export const calculateDistance = (loc1, loc2) => {
    const dx = loc1.lat - loc2.lat;
    const dy = loc1.lon - loc2.lon;
    return Math.sqrt(dx * dx + dy * dy) * 111;
  };
  
  export const generateMockData = (count = 20, userLoc) => {
    const kitaTypes = ["Kindertagesstätte", "Kindergarten"];
    const trägerTypes = ["frei", "städtisch"];
    const tagOptions = ["evangelisch", "integrativ", "Montessori", "bilingual", "Waldorf", "naturnah", "Sport", "Musik", "Kunst", "Natur"];
    const kitaNames = [
      "Sonnenschein", "Regenbogen", "Kleine Entdecker", "Glücksklee", "Sternchen",
      "Traumland", "Kinderparadies", "Zauberwald", "Sonnenkinder", "Regenbogenland",
      "Kinderburg", "Fröhliche Füchse", "Bunte Welt", "Grüne Oase", "Kindernest",
      "Zauberland", "Weltentdecker", "Lachende Herzen", "Pusteblume", "Kunterbunt"
    ];
  
    return Array.from({ length: count }, (_, i) => ({
      id: i + 1,
      name: `${kitaNames[i % kitaNames.length]} Kita`,
      checked: false,
      lat: parseFloat(userLoc.lat) + (Math.random() * 0.1 - 0.05),
      lon: parseFloat(userLoc.lon) + (Math.random() * 0.1 - 0.05),
      details: {
        type: kitaTypes[Math.floor(Math.random() * kitaTypes.length)],
        tags: Array.from(
          { length: 2 + Math.floor(Math.random() * 3) },
          () => tagOptions[Math.floor(Math.random() * tagOptions.length)]
        ),
        träger: trägerTypes[Math.floor(Math.random() * trägerTypes.length)]
      }
    }));
  };
  
  export const updateFilterOptions = (kitasData) => {
    const types = new Set();
    const tags = new Set();
    const träger = new Set();
  
    kitasData.forEach(kita => {
      if (kita.details.type) types.add(kita.details.type);
      if (kita.details.träger) träger.add(kita.details.träger);
      kita.details.tags.forEach(tag => tags.add(tag));
    });
  
    return [
      {
        name: "Type",
        options: Array.from(types).map(type => ({ value: `type:${type}`, label: type }))
      },
      {
        name: "Träger",
        options: Array.from(träger).map(t => ({ value: `träger:${t}`, label: t }))
      },
      {
        name: "Tags",
        options: Array.from(tags).map(tag => ({ value: `tag:${tag}`, label: tag }))
      }
    ];
  };