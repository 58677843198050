import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import { MultiSelect, TagPill, FilterIcon, ExpandIcon } from './components';
import { fetchKitaDetails, fetchUserLocation, fetchNearestLocations, updateFacilityIDs } from './api';
import { calculateDistance, generateMockData, updateFilterOptions } from './utils';

function App() {
  const [kitas, setKitas] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [expandedKitaId, setExpandedKitaId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [filterOptions, setFilterOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [existingFacilityIDs, setExistingFacilityIDs] = useState(null);

  const processApiData = useCallback(async (data, userLoc) => {
    const kitaPromises = data.map(async ([id, distance]) => {
      const kitaDetails = await fetchKitaDetails(id);
      if (kitaDetails) {
        return {
          ...kitaDetails,
          distance,
          checked: false
        };
      }
      return null;
    });

    const kitasWithDetails = (await Promise.all(kitaPromises)).filter(Boolean);
    setKitas(kitasWithDetails.sort((a, b) => a.distance - b.distance));
    setFilterOptions(updateFilterOptions(kitasWithDetails));
  }, []);

  const fetchData = useCallback(async (userLoc) => {
    setIsLoading(true);
    setError(null);
    try {
      const data = await fetchNearestLocations(userLoc);
      await processApiData(data, userLoc);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch data. Using mock data instead.');
      
      const mockKitas = generateMockData(20, userLoc);
      const kitasWithDistance = mockKitas.map(kita => ({
        ...kita,
        distance: calculateDistance(userLoc, { lat: kita.lat, lon: kita.lon })
      }));
      setKitas(kitasWithDistance.sort((a, b) => a.distance - b.distance));
      setFilterOptions(updateFilterOptions(kitasWithDistance));
    } finally {
      setIsLoading(false);
    }
  }, [processApiData]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const uuid = urlParams.get('uuid');

    if (uuid) {
      fetchUserLocation(uuid).then(location => {
        if (location) {
          setUserLocation(location);
          fetchData(location);
        } else {
          setError('Failed to fetch user location. Using default location.');
          const defaultLocation = { lat: 52.52, lon: 13.4 };
          setUserLocation(defaultLocation);
          fetchData(defaultLocation);
        }
      });
    } else {
      setError('No UUID provided. Using default location.');
      const defaultLocation = { lat: 52.52, lon: 13.4 };
      setUserLocation(defaultLocation);
      fetchData(defaultLocation);
    }
  }, [fetchData]);

  const toggleChecked = useCallback((id) => {
    setKitas(prevKitas => prevKitas.map(k => 
      k.id === id ? {...k, checked: !k.checked} : k
    ));
  }, []);

  const toggleExpanded = useCallback((id) => {
    setExpandedKitaId(prevId => prevId === id ? null : id);
  }, []);

  const handleSubmit = useCallback(async () => {
    const selectedKitaIds = kitas.filter(k => k.checked).map(k => k.id);
    if (selectedKitaIds.length === 0) {
      alert("Bitte wählen Sie mindestens eine Kita aus, bevor Sie absenden.");
      return;
      
    }

    

    setIsSubmitting(true);

    const urlParams = new URLSearchParams(window.location.search);
    const uuid = urlParams.get('uuid');
    if (!uuid) {
      setError('No UUID provided. Cannot write back to database.');
      setIsSubmitting(false);
      return;
    }

    try {
      const userData = await fetchUserLocation(uuid);
      if (!userData) {
        throw new Error('User data not found');
      }
      
      const rowId = userData.id;
      const existingFacilityIDs = userData.facilityIDs;

      if (existingFacilityIDs) {
        setExistingFacilityIDs(existingFacilityIDs);
        setShowModal(true);
      } else {
        await updateFacilityIDs(rowId, uuid, selectedKitaIds);
        alert('Your selection has been successfully saved.');
      }
    } catch (error) {
      console.error('Error writing back to database:', error);
      setError('Failed to update database. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  }, [kitas]);

  const handleModalConfirm = useCallback(async () => {
    const selectedKitaIds = kitas.filter(k => k.checked).map(k => k.id);
    const urlParams = new URLSearchParams(window.location.search);
    const uuid = urlParams.get('uuid');
    
    try {
      const userData = await fetchUserLocation(uuid);
      if (userData) {
        await updateFacilityIDs(userData.id, uuid, selectedKitaIds);
        alert('Your selection has been successfully updated.');
      }
    } catch (error) {
      console.error('Error updating facilityIDs:', error);
      setError('Failed to update your selection. Please try again.');
    } finally {
      setShowModal(false);
      setIsSubmitting(false);
    }
  }, [kitas]);

  const handleModalCancel = () => {
    setShowModal(false);
    setIsSubmitting(false);
  };

  const toggleFilter = useCallback((value) => {
    setSelectedFilters(prev => 
      prev.includes(value)
        ? prev.filter(item => item !== value)
        : [...prev, value]
    );
  }, []);

  const clearAllFilters = useCallback(() => {
    setSelectedFilters([]);
  }, []);

  const filteredKitas = kitas.filter(kita => {
    if (selectedFilters.length === 0) return true;
    return selectedFilters.every(filter => {
      const [category, value] = filter.split(':');
      if (category === 'type') return kita.details.type === value;
      if (category === 'träger') return kita.details.träger === value;
      if (category === 'tag') return kita.details.tags.includes(value);
      return true;
    });
  });

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="App">
      <header className="app-header"> 
        <h1 className="app-title">Kita Auswahl</h1>
        <div className="" /> <img src="/kitakids.png" className="App-logo" alt="logo" height={50} />
      </header>
      
      {error && <div className="error-message">{error}</div>}

      <div className="filter-section">
        <MultiSelect
          options={filterOptions}
          selected={selectedFilters}
          toggleOption={toggleFilter}
          clearAll={clearAllFilters}
        />
      </div>

      <ul className="kita-list">
        {filteredKitas.map(k => (
          <li key={k.id} className="kita-item">
            <div className="kita-header" onClick={() => toggleChecked(k.id)}>
              <label className="kita-label">
                <input
                  type="checkbox"
                  checked={k.checked}
                  onChange={() => {}}
                  className="kita-checkbox"
                />
                <span>{k.name}</span>
              </label>
              <span className="kita-distance">{k.distance.toFixed(1)} km</span>
              <button onClick={(e) => { e.stopPropagation(); toggleExpanded(k.id); }} className="expand-button">
                <ExpandIcon isExpanded={expandedKitaId === k.id} />
              </button>
            </div>
            <div className={`kita-details ${expandedKitaId === k.id ? 'expanded' : ''}`}>
              <div className="kita-tags">
                {k.details.type && <TagPill text={k.details.type} color="bg-blue-100" />}
                {k.details.träger && <TagPill text={k.details.träger} color="bg-green-100" />}
                {k.details.tags.filter(tag => tag.trim() !== '').map((tag, index) => (
                  <TagPill key={index} text={tag} color="bg-yellow-100" />
                ))}
              </div>
            </div>
          </li>
        ))}
      </ul>
      
      <div className="bottom-bar">
        <div className="selection-counter">
          <span className={`counter ${filteredKitas.filter(k => k.checked).length > 15 ? 'over-limit' : ''}`}>
            Selected: {filteredKitas.filter(k => k.checked).length}/15
          </span>
          {filteredKitas.filter(k => k.checked).length > 15 && (
            <span className="limit-warning">
              Es können höchstens 15 Einrichtungen kontaktiert werden
            </span>
          )}
        </div>
        <button
          onClick={handleSubmit}
          disabled={isSubmitting || filteredKitas.filter(k => k.checked).length > 15}
          className="submit-button"
        >
          {isSubmitting ? 'Wird übermittelt...' : 'Auswahl absenden'}
        </button>
      </div>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Überschreiben bestätigen</h2>
            <p>Sie haben bereits Einrichtungen ausgewählt. Möchten Sie Ihre vorherige Auswahl überschreiben?</p>
            {/* <p>Vorherige Auswahl: {existingFacilityIDs}</p> */}
            <div className="modal-buttons">
              <button onClick={handleModalConfirm}>Ja, überschreiben</button>
              <button onClick={handleModalCancel}>Abbrechen</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;