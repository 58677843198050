import { API_KEY } from './constants';

const BASE_URL = 'https://api.app.kita.kids'; // Update this to your FastAPI server URL in production

const headers = {
  'Content-Type': 'application/json',
  'X-API-Key': API_KEY
};

export const fetchKitaDetails = async (kitaId) => {
  try {
    const response = await fetch(`${BASE_URL}/kita_details/${kitaId}`, {
      headers
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Kita details');
    }

    const data = await response.json();
    return {
      id: data.id,
      name: data.name,
      lat: data.lat,
      lon: data.lon,
      details: data.details
    };
  } catch (error) {
    console.error('Error fetching Kita details:', error);
    return null;
  }
};

export const fetchUserLocation = async (uuid) => {
  try {
    const response = await fetch(`${BASE_URL}/user_location?uuid=${uuid}`, {
      headers
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user location');
    }

    const data = await response.json();
    return {
      lat: data.lat,
      lon: data.lon,
      id: data.id,
      facilityIDs: data.facilityIDs && data.facilityIDs.length > 0 ? data.facilityIDs : ''
    };
   
  } catch (error) {
    console.error('Error fetching user location:', error);
    return null;
  }
};

export const fetchNearestLocations = async (userLoc) => {
  try {
    const response = await fetch(`${BASE_URL}/nearest_locations`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        lat: userLoc.lat,
        lon: userLoc.lon,
        n: 20
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch nearest locations');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching nearest locations:', error);
    throw error;
  }
};

export const updateFacilityIDs = async (rowId, uuid, selectedKitaIds) => {
  try {
    const response = await fetch(`${BASE_URL}/user_location/${rowId}/${uuid}`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        "facilityIDs": [
          selectedKitaIds.toString()
        ]
      })
    });

    if (!response.ok) {

        console.error(response)
      throw new Error('Failed to update facilityIDs');
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating facilityIDs:', error);
    throw error;
  }
};