import React, { useState, useEffect, useRef } from 'react';

export const TagPill = ({ text, color }) => (
  <span className={`tag-pill ${color}`}>{text}</span>
);

export const FilterIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
  </svg>
);

export const ExpandIcon = ({ isExpanded }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`icon expand-icon ${isExpanded ? 'rotated' : ''}`}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
  </svg>
);

export const MultiSelect = ({ options, selected, toggleOption, clearAll }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getLabel = (value) => {
    const option = options.flatMap(category => category.options).find(opt => opt.value === value);
    return option ? option.label : '';
  };

  const filteredOptions = options.map(category => ({
    ...category,
    options: category.options.filter(option =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    )
  })).filter(category => category.options.length > 0);

  return (
    <div className="multi-select" ref={dropdownRef}>
      <div className="select-header" onClick={() => setIsOpen(!isOpen)}>
        <div className="selected-options">
          {selected.length === 0 ? (
            <span className="placeholder">Filter auswählen</span>
          ) : (
            selected.map(value => (
              <span key={value} className="selected-pill">
                {getLabel(value)}
                <button onClick={(e) => {
                  e.stopPropagation();
                  toggleOption(value);
                }}>×</button>
              </span>
            ))
          )}
        </div>
        <span className={`arrow ${isOpen ? 'up' : 'down'}`}></span>
      </div>
      {isOpen && (
        <div className="options-dropdown">
          <div className="search-container">
            <input
              type="text"
              placeholder="Optionen suchen..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          {filteredOptions.map((category) => (
            <div key={category.name} className="option-category">
              <h3>{category.name}</h3>
              {category.options.map((option) => (
                <label key={option.value} className="option">
                  <input
                    type="checkbox"
                    checked={selected.includes(option.value)}
                    onChange={() => toggleOption(option.value)}
                  />
                  {option.label}
                </label>
              ))}
            </div>
          ))}
          {selected.length > 0 && (
            <button className="clear-all-btn" onClick={clearAll}>Clear All</button>
          )}
        </div>
      )}
    </div>
  );
};